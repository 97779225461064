/**
 * @fileOverview global behaviors - site wrapper, common elements and helper
 * functions only.
 */

var site = site || {};
site.util = site.util || {};

(function($) {
  Drupal.behaviors.adminToolbar = {
    attach: function(context) {
      var $toolbar;

      if (this.attached) {
        return;
      }
      this.attached = true;

      $toolbar = $('#toolbar', context);

      function _checkToolbar() {
        if ($('body').hasClass('toolbar-drawer')) {
          $('body').css('padding-top', $toolbar.height());
        }
      }

      $(window).on('resize-debounced', _checkToolbar);
      _checkToolbar();
      $(document).trigger('check-layout');
    },
    attached: false
  };

  Drupal.behaviors.clientToggle = {
    attach: function() {
      // wire device toggle links
      if (site.client && site.client.controls) {
        site.client.controls();
      }
    }
  };

  /**
   * Triggers a 'resize-debounced' event every 200ms on window resize.
   */
  Drupal.behaviors.throttleResize = {
    attach: function() {
      var _resize;
      if (this.attached) {
        return;
      }
      _resize = _.debounce(function() {
        $(window).trigger('resize-debounced');
      }, 200);
      $(window).on('resize', _resize);
      this.attached = true;
    },
    attached: false
  };

  // @TODO: attach this to the template that contains this element
  Drupal.behaviors.backToTop = {
    attach: function(context) {
      $('.js-sticky-back-to-top', context).off('click.backToTop').on('click.backToTop', function(event) {
        event.preventDefault();
        $('body, html').animate({ scrollTop: 0 }, 400);
      });
    }
  };

  Drupal.behaviors.selectBox = {
    attach: function(context) {
      var self = this;
      // Init SelectBox
      var $loadOnPageLoad = $('.selectbox:not(.js-load-on-demand, .js-load-on-view)', context);
      var $loadOnView = $('.selectbox.js-load-on-view', context);
      self.initSelectBox($loadOnPageLoad);

      if ($loadOnView.length) {
        $loadOnView.each(function() {
          $(this).once('loadOnView').waypoint(function() {
            // init selectBox
            self.initSelectBox($(this));
          }, {offset: 50, triggerOnce: true });
        });
      }

      $('a.js-combobox-label').attr('aria-label', $('select.js-combobox-label').attr('aria-label'));
      if (typeof applySelectBoxAccessibility === 'function') {
        // is defined in ada.js
        applySelectBoxAccessibility(context);
      }
      self.setEvents();
    },
    setEvents: function() {
      var self = this;
      $(document).on('selectbox:loadOnDemand', '.js-load-on-demand', function() {
        $(this).once('loadOnDemand', function() {
          self.initSelectBox($(this));
        });
      });
    },

    initSelectBox: function($elem) {
      var $loadOnMobile = $('.selectbox.js-load-on-mobile');
      var $loopThroughKeys = $('.selectbox.js-loop-through-keys');
      $elem.selectBox({
        // keepInViewport: false --> If set to false, the droplist will be always open towards the bottom
        keepInViewport: true,
        // mobile: false unless .js-load-on-mobile is added and then it will cause it to load on mobile
        mobile: $loadOnMobile.length > 0 ? true : false,
        // the .js-loop-through-keys class enables ada compliance to use the arrow keys to move through selections
        loopOptions: $loopThroughKeys.length > 0 ? true : false,
      });
    }
  };

  // @TODO: make this into a library
  Drupal.behaviors.equalHeights = {
    attach: function(context) {
      // Set elements to the height of tallest element
      $('.equal-height', context).matchHeight();
    }
  };

  // @TODO: attach this to the template that contains this element
  Drupal.behaviors.favoriteLinks = {
    attach: function(context) {
      $('.quickshop__favorite .icon', context).on('click', function(event) {
        event.preventDefault();

        var $this = $(this);
        $this.addClass('icon-heart');
        // Flash the filled-in heart real quick for user feedback
        setTimeout(function() {
          $this.removeClass('icon-heart');
        }, 300);
      });
    }
  };

  // @TODO: what happens if there are more than 1 "read more" link? plus this should use the once() plugin.
  Drupal.behaviors.toggleDiv = {
    attach: function(context) {
      $('.js-read-more', context).on('click', function(event) {
        event.preventDefault();
        $(this).toggleClass('active');
        $('.div-read-more').slideToggle();
      });
    }
  };

  // Stop cancel scroll animations when user scrolls manually.
  $('html, body').bind('scroll mousedown wheel DOMMouseScroll mousewheel keyup', function(e) {
    if (e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel') {
      $('html, body').stop();
    }
  });

  // wrapper for selectBox refresh - important for touch devices...
  site.util.refreshSelectBoxes = function($selectBoxes) {
    $selectBoxes.each(function() {
      var control = $(this).data('selectBox-control');
      if (control && control.length) {
        $(this).selectBox('refresh');
      }
    });
  };
})(jQuery);
